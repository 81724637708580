body {
  margin: 0 auto;
  width: 1000px;
}

button, label {
  margin: 0 1px;
}

th, td {
  border: 1px solid black;
  padding: 5px;
}

.no-border th, .no-border td {
  border: none;
}

.center {
  text-align: center;
}

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.vtop {
  vertical-align: top;
}

.vcenter {
  vertical-align: middle;
}

.inline-block {
  display: inline-block;
}

.m0 {
  margin: 0;
}

.mt16 {
  margin-top: 16px;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w50 {
  width: 50%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.red {
  color: red;
}

.green {
  color: green;
}

.bg-white {
  background-color: white;
}

.bg-red {
  background-color: red;
}

.bg-yellow {
  background-color: yellow;
}

.bg-green {
  background-color: green;
}

.bg-lblue {
  background-color: rgb(220, 246, 255);
}

.bg-black {
  background-color: black;
}

.header h1 {
  margin: 8px;
}

.header .right a {
  margin: 10px 5px;
}

.header .right button {
  margin: 10px 5px;
}

.home .notes {
  border: 1px solid black;
  border-radius: 5px;
}

.home .notes p {
  margin: 5px;
}

.home .notes .title {
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 5px;
}

.home .donate {
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 16px;
}

.home .donate p {
  margin: 5px;
}

.home .donate .title {
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 5px;
}

.home .select-search {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 16px;
}

.home .select-search p {
  margin: 5px;
}

.home .select-search .title {
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 5px;
}

.home .search-results {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 16px;
}

.home .search-results p {
  margin: 5px;
}

.home .search-results .title {
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 5px;
}

.home .search-results .footer {
  border-radius: 0 0 5px 5px;
  margin: 0;
  padding: 5px;
}

.cart .cartitem {
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 auto;
  width: 500px;
}

.cart .cartitem p {
  margin: 5px;
}

